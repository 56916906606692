module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-49527002-7"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-heap/gatsby-browser'),
      options: {"plugins":[],"appId":"3008250108","enableOnDevMode":false},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
