// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-gatsby-mdx-mdx-wrappers-dir-6-ce-604-f-41163404090-c-05-dd-121-c-89088-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/6ce604f41163404090c05dd121c89088--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-6-ce-604-f-41163404090-c-05-dd-121-c-89088-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

